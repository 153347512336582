<template>
  <!-- 主题配置 -->
  <div v-loading="pageLoading" class="themeConfiguration-page">
    <template v-if="channelList.length">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        >
        </el-tab-pane>
      </el-tabs>
      <el-button
        v-if="listFind('新建主题')"
        type="primary"
        size="small"
        @click="addThemeFun"
        >新建主题</el-button
      >
      <el-button
        type="primary"
        v-if="listFind('模块配置')"
        size="small"
        @click="moduleConfigureFun"
        >模块配置</el-button
      >
      <Table
        :table-data.sync="tableData"
        :title-name="titleName"
        :operation="true"
        :col-class-type="true"
        operation-width="150"
      >
        <template slot-scope="scope">
          <div>
            <template v-if="scope.scopeRow.themeType == 2 && listFind('编辑')">
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="updateFun(scope.scopeRow)"
                >编辑</el-button
              >
            </template>
            <template v-if="listFind('配置')">
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="configureFun(scope.scopeRow)"
                >配置</el-button
              >
            </template>
            <template v-if="scope.scopeRow.themeType == 2 && listFind('删除')">
              <el-button
                type="text"
                size="small"
                sort="danger"
                @click="deleteFun(scope.scopeRow)"
                >删除</el-button
              >
            </template>
          </div>
        </template>
      </Table>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="pagination.pageSize"
          :current-page="pagination.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="主题名称：" prop="themeName">
          <el-input
            v-model="ruleForm.themeName"
            placeholder="请输入主题名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="主题类型：" required>
          <el-select
            v-model="ruleForm.themeType"
            disabled
            placeholder="请选择主题类型"
          >
            <el-option
              v-for="item in themeTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="有效期限："
          required
          :prop="ruleForm.themeType === 1 ? '' : 'validityTime'"
        >
          <el-date-picker
            v-model="ruleForm.validityTime"
            type="daterange"
            range-separator="至"
            :disabled="isDisabledPicker"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogAddThemeFun">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  querySystemThemePageAPI,
  updateSystemThemeAPI,
  saveSystemThemeAPI,
  deleteSystemThemeByIdAPI,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";
import moment from "moment";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      channelNo: null,
      channelName: null,
      channelList: [],
      dialogTitle: "",
      dialogVisible: false,
      ruleForm: {
        themeName: "",
        themeType: "",
        validityTime: "",
      },
      rules: {
        themeName: [
          { required: true, message: "请输入主题名称", trigger: "blur" },
        ],
        validityTime: [
          { required: true, message: "请选择有效期限", trigger: "blur" },
        ],
      },
      isDisabledPicker: false,
      startPlaceholder: "开始时间",
      endPlaceholder: "结束时间",
      themeTypeList: [
        { label: "默认主题", value: 1 },
        { label: "活动主题", value: 2 },
      ],
      total: 0,
      pagination: {
        pageSize: 10,
        currentPage: 1,
      },
      tableData: [],
      titleName: [
        {
          title: "主题名称",
          props: "themeName",
          width: 200,
        },
        {
          title: "主题类型",
          props: "themeType",
          SpecialJudgment: (res) => {
            return res == 1 ? "默认主题" : "活动主题";
          },
        },
        {
          title: "有效期起",
          props: "startValidTime",
          width: 200,
          render: (h, params, index) => {
            return h(
              "span",
              params.themeType == 1 ? "长期" : params.startValidTime
            );
          },
        },
        {
          title: "有效期止",
          props: "endValidTime",
          width: 200,
          render: (h, params, index) => {
            return h(
              "span",
              params.themeType == 1 ? "长期" : params.endValidTime
            );
          },
        },
        {
          title: "启用",
          props: "enableStatus",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.enableStatus,
                "active-value": 1,
                "inactive-value": 0,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    enableStatus: val,
                    endValidTime: params.endValidTime,
                    id: params.id,
                    startValidTime: params.startValidTime,
                    themeName: params.themeName,
                    themeType: params.themeType,
                  };
                  updateSystemThemeAPI(obj).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.$message.success("修改成功");
                      this.renderData();
                    }
                  });
                },
              },
            });
          },
        },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {
    this.getQueryChannelList();
  },
  mounted() {},
  methods: {
    // 表格数据渲染
    renderData() {
      this.pagination.channelNo = this.channelNo;
      querySystemThemePageAPI(this.pagination).then((res) => {
        if (res.code == "SUCCESS") {
          this.total = res.data.total || 0;
          this.tableData = res.data.list || [];
        }
      });
    },
    //点击渠道tab项
    handleClick(val) {
      this.channelName = val.label;
      this.renderData();
    },
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data;
            this.channelNo = res.data[0]?.channelNo;
            this.channelName = res.data[0]?.channelName;
            if (!res.data.length) {
              this.$message.error("暂无权限，请联系管理员");
              this.emptyFlag = true;
              this.pageLoading = false;
              return;
            }
            this.pageLoading = false;
            this.renderData();
          }
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    //新建主题
    addThemeFun() {
      this.dialogTitle = "新建";
      this.dialogVisible = true;
      if (this.tableData.length > 0) {
        this.ruleForm.themeType = 2;
        this.isDisabledPicker = false;
      } else {
        this.ruleForm.themeType = 1;
        this.startPlaceholder = "长期";
        this.endPlaceholder = "长期";
        this.isDisabledPicker = true;
      }
    },
    dialogAddThemeFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.ruleForm.startValidTime = moment(
            this.ruleForm.validityTime[0]
          ).format("yyyy-MM-DD");
          this.ruleForm.endValidTime = moment(
            this.ruleForm.validityTime[1]
          ).format("yyyy-MM-DD");
          let path =
            this.dialogTitle === "新建"
              ? saveSystemThemeAPI
              : updateSystemThemeAPI;
          let obj = this.deepClone(this.ruleForm);
          delete obj.validityTime;
          if (obj.themeType === 1) {
            delete obj.startValidTime;
            delete obj.endValidTime;
          }
          obj.channelNo = this.channelNo;
          path(obj).then((res) => {
            if (res.code == "SUCCESS") {
              this.dialogVisible = false;
              this.$message.success("操作成功");
              this.renderData();
            }
          });
        }
      });
    },
    //模块配置
    moduleConfigureFun() {
      this.$router.push("/projectTravel/moduleConfiguration");
    },
    //编辑主题
    updateFun(row) {
      this.dialogTitle = "编辑";
      this.dialogVisible = true;
      this.ruleForm.themeName = row.themeName;
      this.ruleForm.themeType = row.themeType;
      this.ruleForm.channelNo = this.channelNo;
      this.ruleForm.validityTime = [row.startValidTime, row.endValidTime];
      this.ruleForm.id = row.id;
    },
    //配置
    configureFun(row) {
      this.$router.push(
        `/projectTravel/operationTheme?id=${row.id}&channelName=${this.channelName}`
      );
    },
    //删除
    deleteFun(row) {
      this.$confirm("此操作将删除该主题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSystemThemeByIdAPI({ id: row?.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.renderData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.pagination.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.pagination.currentPage = 1;
      this.renderData();
    },
    handleClose() {
      this.ruleForm = {
        themeName: "",
        themeType: "",
        validityTime: "",
      };
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.themeConfiguration-page {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: 100%;
  .Table {
    margin-top: 20px;
  }
  .demo-ruleForm {
    .el-input,
    .el-select,
    .el-date-editor {
      width: 70%;
    }
    ::v-deep .el-range-separator {
      line-height: 24px;
    }
  }
}
</style>
